import { Payment } from '@payment/payment-sdk';

app.service('slPaymentService', [
  '$http',
  'mainConfig',
  function ($http, mainConfig) {
    const SHOPLINE_PAYMENT_CLIENT_ID = 'EC1.0';

    const getPaymentInstruments = () => {
      return $http({
        method: 'GET',
        url: '/api/shopline_payment/payment_instruments',
      });
    };

    const getCustomerToken = () => {
      return $http({
        method: 'GET',
        url: '/api/shopline_payment/customer_token',
      });
    };

    const getDeviceType = () => {
      if (/iPad/.test(navigator.userAgent)) {
        return 'pad';
      }

      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
          navigator.userAgent,
        )
      ) {
        return 'phone';
      }

      return 'pc';
    };

    const paymentMethodMap = {
      credit: 'CreditCard',
      atm_virtual_account: 'VirtualAccount',
      fpx: 'FPX',
      atome: 'AtomeBNPL',
      chailease: 'ChaileaseBNPL',
      alipay: getDeviceType() === 'phone' ? 'AlipayWap' : 'AlipayQrCode',
      unionpay: 'Unionpay',
      applepay: 'ApplePay',
      jkopay: 'JKOPay',
      wechatpay: 'WeChatPay',
      googlepay: 'GooglePay',
    };

    const getCountry = (countryCode) => {
      const countryList = [
        'zh-cn',
        'zh-hant',
        'en',
        'de',
        'ru',
        'fr',
        'es',
        'ja',
        'th',
      ];
      const targetCountry = countryList.find(function (country) {
        return countryCode === country;
      });
      if (targetCountry) {
        if (targetCountry === 'zh-hant') {
          return 'zh-TW';
        } else if (targetCountry === 'zh-cn') {
          return 'zh-CN';
        }

        return targetCountry;
      }
      return 'en';
    };

    const getDefaultPaymentConfig = (paymentMethod) => ({
      clientId: SHOPLINE_PAYMENT_CLIENT_ID,
      paymentMethod:
        paymentMethodMap[
          paymentMethod?.config_data?.shopline_payment_payment
        ] || 'CreditCard',
      merchantId: mainConfig.merchantData.sl_payment_merchant_id,
      channelId: 'SLPayments',
      countryCode: mainConfig.merchantData.base_country_code,
      currency: mainConfig.merchantData.base_currency_code,
      language: getCountry(mainConfig.localeData.loadedLanguage.code),
      installmentCounts: paymentMethod?.config_data?.installments || [],
      env: mainConfig.shoplinePaymentV2Env,
      customerToken: '',
      paymentInstrument: {
        bindCard: {
          enable: false,
          protocol: {
            mustAccept: false,
            textType: {
              paymentAgreement: false,
            },
            defaultSwitchStatus: false,
            switchVisible: false,
          },
        },
      },
      eventTrackingConfig: {
        url: 'https://events.shoplytics.com/api/v1/slp',
        mode: 'ajax',
        method: 'post',
      },
    });

    const renderSDK = ({ paymentConfig, elementId }) => {
      return new Payment(paymentConfig, elementId).init();
    };

    return {
      getPaymentInstruments,
      getCustomerToken,
      getDeviceType,
      getDefaultPaymentConfig,
      paymentMethodMap,
      renderSDK,
    };
  },
]);
